import React from "react";
import Slider from "react-slick";
import { TestimonialOneParams } from "../../utils/script";
import clients from "../../config/clients.json";

const testimonials = clients.slice(3, clients.length);

const TestimonialOne = (props) => {
  const classes = `testimonial-activation testimonial-style-1 edu-slick-button slick-button-left ${
    props.className ? props.className : ""
  }`;

  return (
    <Slider className={classes} {...TestimonialOneParams}>
      {testimonials.map((item, i) => (
        <div className="single-testimonial" key={i}>
          <div className="inner">
            {props.quoteIcon !== "disable" && (
              <div className="quote-sign">
                <img
                  src="/images/testimonial/testimonial-01/quote.png"
                  alt="Quote Images"
                />
              </div>
            )}
            <p
              className="description"
              style={{
                overflow: "hidden",
                maxHeight: "180px",
                textOverflow: "ellipsis",
              }}
            >
              “ {item.text}”
            </p>
            <div className="client-information">
              <div className="thumb">
                <img src={item.imageSrc} alt="Client Thumb" />
              </div>
              <div className="info">
                <h5 className="title">{item.name}</h5>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};
export default TestimonialOne;
