import React, { useEffect, useState } from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import PaginationOne from "../../components/pagination/PaginationOne";
import { LessonCard } from "../../components/post/LessonCard";
import { useGetLessons } from "../../services/xataDbGetLessons";

const LessonsPage = () => {
  const getLessons = useGetLessons();
  const { lessons } = getLessons || [];

  return (
    <>
      <SEO title="Lessons" />
      <Layout>
        <BreadcrumbOne
          title="Lessons"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Lessons"
        />
        <div className="edu-elements-area edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="row g-5">
                  {lessons.map((item) => (
                    <div className="col-lg-4 col-md-6 col-12" key={item.id}>
                      <LessonCard data={item} />
                    </div>
                  ))}
                </div>
                {/* <div className="row">
                  <div className="col-lg-12 mt--60">
                    <PaginationOne />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default LessonsPage;
