import React from "react";
import SEO from "../../common/SEO";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import AboutSeven from "../../components/about/AboutSeven";
import AboutUsOneService from "../../components/about-us-one/AboutUsOneService";
import AboutSix from "../../components/about/AboutSix";
import AboutOne from "../../components/about/AboutThree";
import HeaderOne from "../../common/header/HeaderOne";
import AboutUsOneTeam from "../../components/about-us-one/AboutUsOneTeam";
// import HomeTwoTestimonial from '../../components/home-two/HomeTwoTestimonial';
import FooterOne from "../../common/footer/FooterOne";

const AboutUsOne = () => {
  return (
    <>
      <SEO title="About Me" />

      <HeaderOne />

      <BreadcrumbOne
        title="About Me"
        rootUrl="/"
        parentUrl="Home"
        currentUrl="About Me"
      />

      <AboutSeven />

      <AboutUsOneService />

      {/* <AboutSix /> */}

      {/* <AboutOne /> */}

      {/* <AboutUsOneTeam /> */}

      {/* <HomeTwoTestimonial /> */}

      <FooterOne />
    </>
  );
};

export default AboutUsOne;
