import { useState } from "react";
import FsLightbox from "fslightbox-react";
import { FaPlay } from "react-icons/fa";
import { buildThumbnailFromYoutubeVideoURL } from "../../utils/index";
import { videosFromClients } from "../../config/videosFromClients";
import Slider from "react-slick";

// const PrevArrow = (props) => {
//   const { onClick } = props;
//   return (
//     <button className="edu-btn slide-arrow prev-arrow" onClick={onClick}>
//       <i className="icon-arrow-left-line"></i>
//     </button>
//   );
// };

// const NextArrow = (props) => {
//   const { onClick } = props;
//   return (
//     <button className="edu-btn slide-arrow next-arrow" onClick={onClick}>
//       <i className="icon-arrow-right-line"></i>
//     </button>
//   );
// };

const sliderSettings = {
  dots: true,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerMode: false,
  //   nextArrow: <NextArrow />,
  //   prevArrow: <PrevArrow />,
  autoPlay: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 577,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const VideoContainer = ({ videoId, toggler, setToggler }) => (
  <div className="thumbnail video-popup-wrapper">
    <img
      className="radius-small"
      src={buildThumbnailFromYoutubeVideoURL(videoId)}
      alt="Video PopUp Thumb"
    />
    <button
      className="video-play-btn with-animation position-to-top video-popup-activation color-secondary size-80"
      onClick={() => setToggler(!toggler)}
    >
      <span>
        <FaPlay className="play-icon" />
      </span>
    </button>
  </div>
);

const VideoSectionOne = () => {
  const [toggler, setToggler] = useState(false);
  const [videoIndex, setVideoSliderIndex] = useState(0);

  const handleVideoClick = (state, index) => {
    setVideoSliderIndex(index);
    setToggler(state);
  };

  return (
    <>
      <div className="eduvibe-home-four-video edu-video-area edu-section-gap video-style-2">
        <div className="row g-5">
          <div className="col-lg-12">
            <div className="section-title text-center">
              <h3 className="title">Video Feedbacks</h3>
            </div>
          </div>
        </div>

        <div className="container eduvibe-animated-shape">
          <Slider
            className="eduvibe-post-one-carousel-wrapper mt--40 mb--50 edu-slick-button"
            {...sliderSettings}
          >
            {videosFromClients.map((videoItem, index) => (
              <div
                className="eduvibe-post-one-carousel-item"
                key={videoItem.videoId}
              >
                <VideoContainer
                  videoId={videoItem.videoId}
                  toggler={toggler}
                  setToggler={(state) => handleVideoClick(state, index)}
                />
              </div>
            ))}
          </Slider>

          <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
            <div className="shape-image shape-image-1">
              <img src="/images/shapes/shape-03-10.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-2">
              <img src="/images/shapes/shape-19-03.png" alt="Shape Thumb" />
            </div>
          </div>
        </div>

        <div className="side-shape-image d-lg-block d-none">
          <img
            src="/images/videopopup/video-infinite-rotate.png"
            alt="Shape Images"
          />
        </div>
      </div>

      <FsLightbox
        slide={videoIndex + 1}
        toggler={toggler}
        sources={videosFromClients.map((video) => video.videoURL)}
        maxYoutubeVideoDimensions={{ width: 900, height: 550 }}
      />
    </>
  );
};

export default VideoSectionOne;
