import React, { useState, useContext } from "react";
import { Accordion } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import { useGetFAQList } from "../../services/hygraphCms";

const CustomToggle = ({ children, eventKey }) => {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey);
  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <button
      type="button"
      onClick={decoratedOnClick}
      aria-expanded={isCurrentEventKey ? true : false}
      className="edu-accordion-button"
    >
      {children}
    </button>
  );
};

const Faq = ({ wrapperClass }) => {
  const { data } = useGetFAQList();
  const faqsList = (data && data.faqs) || [];

  const [selectedItem, setSelectedItem] = useState("online-education");
  const [activeId1, setActiveId1] = useState("0");
  const [activeId2, setActiveId2] = useState("0");
  const [activeId3, setActiveId3] = useState("0");
  const [activeId4, setActiveId4] = useState("0");
  const [activeId5, setActiveId5] = useState("0");
  const [activeId6, setActiveId6] = useState("0");

  function toggleActive1(id) {
    if (activeId1 === id) {
      setActiveId1(null);
    } else {
      setActiveId1(id);
    }
  }
  function toggleActive2(id) {
    if (activeId2 === id) {
      setActiveId2(null);
    } else {
      setActiveId2(id);
    }
  }
  function toggleActive3(id) {
    if (activeId3 === id) {
      setActiveId3(null);
    } else {
      setActiveId3(id);
    }
  }
  function toggleActive4(id) {
    if (activeId4 === id) {
      setActiveId4(null);
    } else {
      setActiveId4(id);
    }
  }
  function toggleActive5(id) {
    if (activeId5 === id) {
      setActiveId5(null);
    } else {
      setActiveId5(id);
    }
  }
  function toggleActive6(id) {
    if (activeId6 === id) {
      setActiveId6(null);
    } else {
      setActiveId6(id);
    }
  }

  return (
    <>
      <SEO title="Frequently Asked Questions" />
      <Layout>
        <BreadcrumbOne
          title="Frequently Asked Questions"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="FAQ"
        />

        <div
          className={`edu-accordion-area accordion-shape-1 edu-section-gap bg-color-white ${
            wrapperClass ? wrapperClass : ""
          } `}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <span className="pre-title">FaQ Questions</span>
                  <h3 className="title">
                    Get Every General Answers <br /> From Here
                  </h3>
                </div>
              </div>
            </div>

            {selectedItem === "online-education" && (
              <div className="row g-5 align-items-center  mt--20">
                <div className="col-lg-12">
                  <div className="accordion-style-1">
                    <Accordion
                      bsPrefix="edu-accordion"
                      defaultActiveKey="0"
                      flush
                    >
                      {faqsList.map((faqItem) => (
                        <Accordion.Item
                          eventKey={faqItem.id.toString()}
                          key={faqItem.id}
                          onClick={() => toggleActive2(faqItem.id.toString())}
                          bsPrefix={`edu-accordion-item ${
                            activeId2 === faqItem.id.toString()
                              ? "active-item"
                              : ""
                          }`}
                        >
                          <div className="edu-accordion-header">
                            <CustomToggle eventKey={faqItem.id.toString()}>
                              {faqItem.question}
                            </CustomToggle>
                          </div>
                          <Accordion.Body bsPrefix="edu-accordion-body">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: faqItem.answer.html,
                              }}
                            ></div>
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Faq;
