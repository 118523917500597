import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ParallaxProvider } from "react-scroll-parallax";
import { GoogleOAuthProvider } from "@react-oauth/google";

import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  gql,
  useQuery,
} from "@apollo/client";

// Initialize Apollo Client
const client = new ApolloClient({
  uri: "https://api-eu-central-1.hygraph.com/v2/ckewp5vorpwyu01z47rch1t9m/master",
  cache: new InMemoryCache(),
});

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <GoogleOAuthProvider clientId="66101992908-v1nrb7c5st9dpeekahs8bqih9a1hbpfn.apps.googleusercontent.com">
    <ApolloProvider client={client}>
      <ParallaxProvider>
        <App />
      </ParallaxProvider>
    </ApolloProvider>
  </GoogleOAuthProvider>
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
