import "./style.scss";

export const PartnerShipIcons = () => (
  <div className="row g-5 align-items-center mt--10 mb--45 mb--30 mt_md--30 mt_sm--30">
    <div className="section-title text-center mb-4">
      <span className="pre-title">In partnership with</span>
    </div>

    <div className="list-of-partners-icons">
      <img
        src="https://firebasestorage.googleapis.com/v0/b/hapyspace-assets.appspot.com/o/ikea.svg?alt=media&amp;token=ecefa182-31b1-4505-b2d8-79d1927533ff"
        alt="IKEA"
      />
      <img
        src="https://firebasestorage.googleapis.com/v0/b/hapyspace-assets.appspot.com/o/carelon.svg?alt=media&amp;token=ecefa182-31b1-4505-b2d8-79d1927533ff"
        alt="carelon"
      />
      <img
        src="https://firebasestorage.googleapis.com/v0/b/hapyspace-assets.appspot.com/o/masterschool.png?alt=media&amp;token=ecefa182-31b1-4505-b2d8-79d1927533ff"
        alt="masterschool"
      />
      <img
        src="https://firebasestorage.googleapis.com/v0/b/hapyspace-assets.appspot.com/o/ionix.svg?alt=media&amp;token=ecefa182-31b1-4505-b2d8-79d1927533ff"
        alt="ionix.io"
      />
      <img
        src="https://firebasestorage.googleapis.com/v0/b/hapyspace-assets.appspot.com/o/beyondair.png?alt=media&amp;token=ecefa182-31b1-4505-b2d8-79d1927533ff"
        alt="beyondair"
      />
      <img
        src="https://firebasestorage.googleapis.com/v0/b/hapyspace-assets.appspot.com/o/yeda.png?alt=media&amp;token=ecefa182-31b1-4505-b2d8-79d1927533ff"
        alt="yeda-eng"
      />
      <img
        src="https://firebasestorage.googleapis.com/v0/b/hapyspace-assets.appspot.com/o/qnomy.svg?alt=media&amp;token=ecefa182-31b1-4505-b2d8-79d1927533ff"
        alt="qnomy"
      />
    </div>
  </div>
);
