import bcrypt from "bcryptjs";

import { getXataClient } from "./xata";
const xata = getXataClient();

export type User = {
  email: string;
  password: string;
};

export async function verifyLogin(args: User) {
  const { email, password } = args;
  const userWithPassword = await xata.db.users.filter({ email }).getFirst();

  if (!userWithPassword || !userWithPassword.password) {
    return null;
  }

  const isValid = await bcrypt.compare(password, userWithPassword.password);

  if (!isValid) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { password: _password, ...userWithoutPassword } = userWithPassword;

  return userWithoutPassword;
}
