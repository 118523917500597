import React from "react";
import Slider from "react-slick";
import { TestimonialFourParams } from "../../utils/script";
import data from "../../config/clients.json";

const TestimonialFour = (props) => {
  const classes = `eduvibe-testimonial-four edu-testimonial-activation testimonial-item-3 mt--40 edu-slick-button ${
    props.className ? props.className : ""
  }`;

  return (
    <Slider className={classes} {...TestimonialFourParams}>
      {data.map((item, i) => (
        <div className="testimonial-card-box" key={i}>
          <div className="inner">
            <div className="client-info">
              <div className="thumbnail">
                <img src={item.imageSrc} alt="Client Thumb" />
              </div>
              <div className="content">
                <h6 className="title">{item.name}</h6>
                {/* <span className="designation">{item.designation}</span> */}
              </div>
            </div>
            <p
              className="description"
              style={{
                overflow: "hidden",
                maxHeight: "180px",
                textOverflow: "ellipsis",
              }}
            >
              “ {item.text} ”
            </p>
            <div className="rating eduvibe-course-rating-stars">
              {[...Array(5)].map((_, index) => {
                return (
                  <i
                    key={index}
                    className={index < 10 ? "on icon-Star" : "off icon-Star"}
                  ></i>
                );
              })}
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default TestimonialFour;
