import { Link } from "react-router-dom";
import { buildThumbnailFromYoutubeVideoURL } from "../../utils/index";

export const LessonCard = ({ data, classes, bgWhite }) => {
  return (
    <div
      className={`edu-blog blog-type-2 radius-small ${classes ? classes : ""} ${
        bgWhite === "enable" ? "bg-color-white" : ""
      }`}
    >
      <div className="inner">
        <div className="thumbnail">
          <Link to={process.env.PUBLIC_URL + `/lessons/${data.id}`}>
            <img
              src={buildThumbnailFromYoutubeVideoURL(data.videoId)}
              alt="Blog Thumb"
            />
          </Link>
        </div>
        <div className="content">
          {/* <div className="status-group">
            <Link className="eduvibe-status status-05" to={""}>
              <i className="icon-price-tag-3-line"></i>{" "}
            </Link>
          </div> */}
          <h5 className="title">
            <Link to={process.env.PUBLIC_URL + `/blog-details/${data.id}`}>
              {data.title}
            </Link>
          </h5>
          <div className="blog-card-bottom">
            <ul className="blog-meta">
              <li>
                <i className="icon-calendar-2-line"></i>
                {new Date(data.xata.createdAt).toDateString()}
              </li>
            </ul>
          </div>
          <div className="read-more-btn">
            <Link
              className="btn-transparent"
              to={process.env.PUBLIC_URL + `/lessons/${data.id}`}
            >
              View Lesson & Exercise
              <i className="icon-arrow-right-line-right"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
