import React from 'react';
import { Typewriter } from "react-simple-typewriter";
import { Link } from 'react-router-dom';

const BannerThree = () => {
    return (
        <div className="slider-area banner-style-3 bg-image">
            <div className="d-flex align-items-center height-940">
                <div className="container eduvibe-animated-shape">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <div className="inner">
                                <div className="content text-start">
                                    <span className="pre-title">Better Learning Future With Us</span>
                                    <h1 className="title">Mastering English with David</h1>
                                    <p className="description" style={{
                                        height: '30px',
                                    }}>
                                        Empower your English Language skills with&nbsp;
                                         <Typewriter
                                            typeSpeed={55}
                                            deleteSpeed={30}
                                            delaySpeed={1800}
                                            loop={true}
                                            words={[
                                            "Expert Guidance",
                                            "Personalized Lessons",
                                            "Fostering Fluency",
                                            ]}
                                        />
                                    </p>
                                    <Link className="edu-btn" to="#">Get Started Today <i className="icon-arrow-right-line-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="banner-image">
                                <div className="banner-main-image">
                                    <img className="img-01" src="https://firebasestorage.googleapis.com/v0/b/hapyspace-assets.appspot.com/o/david.jpg?alt=media&token=e3a81957-1789-4acf-8858-19c10e04becd" alt="Banner Images" style={{
                                        width: '100%',
                                        borderRadius: '20px',
                                    }} />
                                </div>
                                {/* <img className="img-02" src="https://firebasestorage.googleapis.com/v0/b/hapyspace-assets.appspot.com/o/david-english%2Fhomepage-sliders%2Fslide-3.webp?alt=media&amp;token=aaf4a3fb-77c4-46ac-88b6-8bcda9417df1" alt="Banner Images" style={{
                                        width: '50%',
                                        borderRadius: '20px',
                                    }}/>
                                <img className="img-03" src="https://firebasestorage.googleapis.com/v0/b/hapyspace-assets.appspot.com/o/david-english%2Fhomepage-sliders%2Fslide-5.webp?alt=media&amp;token=351f568f-a851-4414-88db-094a8170c388" alt="Banner Images" style={{
                                    width: '40%',
                                    borderRadius: '20px',
                                }}/> */}
                            </div>
                        </div>
                    </div>

                    <div className="shape-wrapper">
                        <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                            <div className="shape-image shape-image-1">
                                <img src="/images/shapes/shape-11-03.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-2">
                                <img src="/images/shapes/shape-01-02.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-3">
                                <img src="/images/shapes/shape-13-06-07.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-4">
                                <img src="/images/shapes/shape-26.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-5">
                                <img src="/images/shapes/shape-05-01.png" alt="Shape Thumb" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BannerThree;
