import { googleLogout, useGoogleLogin } from "@react-oauth/google";

// const buildUserInfoUrl = (accessToken: string) =>
//   `https://www.googleapis.com/oauth2/v2/userinfo?access_token=${accessToken}`;

const buildUserInfoUrl = (accessToken: string) =>
  `https://www.googleapis.com/oauth2/v2/userinfo`;

export const getGoogleUserProfile = (accessToken, setProfile) => {
  fetch(buildUserInfoUrl(accessToken), {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
    },
  })
    .then((res: any) => {
      setProfile(res.data);
    })
    .catch((err) => console.log(err));
};

export { googleLogout, useGoogleLogin };

// export const login = (setUser) =>
//   useGoogleLogin({
//     onSuccess: (codeResponse) => setUser(codeResponse),
//     onError: (error) => console.log("Login Failed:", error),
//   });

export const logOut = (setProfile) => {
  googleLogout();
  setProfile(null);
};

// export const googleLoginWithAuth = useGoogleLogin({
//   flow: "auth-code",
//   onSuccess: async (codeResponse) => {
//     console.log(codeResponse);
//     const tokens = await fetch("http://localhost:3000/auth/google", {
//       method: "post",
//       body: JSON.stringify({ code: codeResponse.code }),
//     });

//     console.log(tokens);
//   },
//   onError: (errorResponse) => console.log(errorResponse),
// });
