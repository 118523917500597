import { getXataClient } from "./xata";

const xata = getXataClient();

export const persistCorrectAnswer = async (args: {
  exerciseId: string;
  correctAnswers: string[];
}) => {
  const status = "in-progress";
  let exercise;

  try {
    exercise = await xata.db.exercises.update(args.exerciseId, {
      correctAnswers: args.correctAnswers,
      status: status,
    });
  } catch (error) {
    console.log(error);
  }

  return exercise;
};
