import { useState, useEffect } from "react";
import { getXataClient } from "./xata";

const xata = getXataClient();

export const useGetLessonById = ({ id }: { id: string }) => {
  const [lesson, setLesson] = useState({});

  useEffect(() => {
    (async function () {
      const record = await xata.db.lessons.filter({ id }).getFirst();
      setLesson(record);
    })();
  }, []);

  return { lesson };
};
