import React from "react";
import { ApolloClient, InMemoryCache, gql, useQuery } from "@apollo/client";

// Initialize Apollo Client
const client = new ApolloClient({
  uri: "https://api-eu-central-1.hygraph.com/v2/ckewp5vorpwyu01z47rch1t9m/master",
  cache: new InMemoryCache(),
});

// GraphQL query
const GET_FAQ_QUERY = gql`
  query GetFAQ {
    faqs(locales: en) {
      id
      question
      answer {
        html
      }
    }
  }
`;

export const useGetFAQList = () => {
  const { loading, error, data } = useQuery(GET_FAQ_QUERY);
  return { loading, error, data };
};

// // React component to fetch and display data
// function FAQ() {
//   const { loading, error, data } = useQuery(GET_FAQ_QUERY);

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>Error :(</p>;

//   console.log(data);

//   return (
//     <div>
//       GQL DATA HERE
//       {/* {data.items.map(({ id, question, answer }) => (
//         <div key={id}>
//           <h3>{question}</h3>
//           <p>{answer.html}</p>
//         </div>
//       ))} */}
//     </div>
//   );
// }

// export default FAQ;
