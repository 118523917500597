import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const SectionTitle = ({ slogan, title, classes }) => {
  return (
    <div className={`section-title ${classes ? classes : ""}`}>
      <ScrollAnimation
        // delay={1000}
        animateIn="fadeInUp"
        animateOut="fadeInOut"
        // animateOnce={true}
      >
        <span
          className="pre-title"
          dangerouslySetInnerHTML={{ __html: slogan }}
        />
      </ScrollAnimation>

      <ScrollAnimation
        // delay={1000}
        animateIn="fadeInUp"
        animateOut="fadeInOut"
        // animateOnce={true}
      >
        <h3 className="title" dangerouslySetInnerHTML={{ __html: title }} />
      </ScrollAnimation>
    </div>
  );
};
export default SectionTitle;
