import React from "react";
import SEO from "../../common/SEO";
import HeaderTwo from "../../common/header/HeaderTwo";
import BannerThree from "../../components/banner/BannerThree";
import { PartnerShipIcons } from "../../PartnershipIconsSection";
import AboutFive from "../../components/about/AboutFive";
import TestimonialSectionTwo from "../../components/testimonial-section/TestimonialSectionTwo";
import NewsLetterFormTwo from "../../components/newsletter/NewsLetterTwo";
import FooterOne from "../../common/footer/FooterOne";
import TeachingApproachFlow from "../../TeachingApproachFlow";

const HomeThree = () => {
  return (
    <>
      <SEO title="Mastering English with David" />

      <HeaderTwo styles="header-transparent header-style-2" />

      <BannerThree />

      {/* <HomeThreeService /> */}

      {/* <AboutFive /> */}

      {/* <HomeThreeCourse /> */}

      {/* <AboutSix /> */}

      {/* <AboutTwo /> */}

      <TestimonialSectionTwo />

      <PartnerShipIcons />
      {/* <HomeThreeEvent /> */}

      <NewsLetterFormTwo />

      <FooterOne />
    </>
  );
};

export default HomeThree;
