import TeachingApproachFlow from "../../TeachingApproachFlow";
import SEO from "../../common/SEO";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import FooterOne from "../../common/footer/FooterOne";
import HeaderOne from "../../common/header/HeaderOne";

export const TeachingapproachPage = () => (
  <>
    <SEO title="About Me" />

    <HeaderOne styles={{}} disableSticky={{}} />

    <BreadcrumbOne
      title="Teaching Approach"
      rootUrl="/"
      parentUrl="Home"
      currentUrl="Teaching Approach"
    />

    <TeachingApproachFlow />

    <FooterOne />
  </>
);
