import { ExerciseStatus } from "../types/Lesson";
import { getXataClient } from "./xata";

const xata = getXataClient();

export const startExerciseAPI = async ({ lessonId, userId }) => {
  const exercise = await xata.db.exercises.create({
    userId: userId,
    lessonId: lessonId,
    status: "in-progress",
  });

  return exercise;
};

export const getExerciseById = async ({ userId, lessonId }) => {
  const exercise = await xata.db.exercises
    .filter({
      userId: userId,
      lessonId: lessonId,
    })
    .getFirst();

  return exercise;
};

// Update exercise status to complete
export const persistExerciseCompleted = async (args: {
  exerciseId: string;
}) => {
  const status: ExerciseStatus = "complete";

  const exercise = await xata.db.exercises.update(args.exerciseId, {
    status: status,
  });

  return exercise;
};
