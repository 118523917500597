import React, { useState } from "react";

const formActionURL = "https://formspree.io/f/xdoqgopz";

function ContactMeForm({ formStyle }) {
  return (
    <form className={`${formStyle}`} action={formActionURL} method="POST">
      <div className="col-lg-6">
        <div className="form-group">
          <input
            type="text"
            className="form-control form-control-lg"
            name="fullname"
            placeholder="Name*"
            required
          />
        </div>
      </div>

      <div className="col-lg-6">
        <div className="form-group">
          <input
            type="email"
            name="email"
            className="form-control form-control-lg"
            placeholder="Email*"
            required
          />
        </div>
      </div>

      <div className="col-lg-12">
        <div className="form-group">
          <input
            type="text"
            className="form-control form-control-lg"
            name="subject"
            placeholder="Subject"
          />
        </div>
      </div>
      <div className="col-lg-12">
        <div className="form-group">
          <textarea
            className="form-control"
            name="message"
            placeholder="Your comment"
          />
        </div>
      </div>
      <div className="col-lg-12">
        <button className="rn-btn edu-btn" type="submit">
          <span>Submit Now</span>
          <i className="icon-arrow-right-line-right"></i>
        </button>
      </div>
    </form>
  );
}
export default ContactMeForm;
