import { useState, useEffect } from "react";
import { ExerciseAudioIndicators } from "./ExerciseAudioIndicators";

import "./style.scss";
import { Exercise } from "../types/exercise";

type ExerciseItemProps = {
  exercise: Exercise;
  handleCorrectAnswer: React.Dispatch<React.SetStateAction<string>>;
  audioCorrectRef: React.MutableRefObject<HTMLAudioElement | undefined>;
  correctExerciseIds: string[];
};

export const ExerciseItem: React.FC<ExerciseItemProps> = (props) => {
  const { exercise, handleCorrectAnswer, audioCorrectRef } = props;
  const correctExerciseIds = props.correctExerciseIds || [];
  const [showAnswer, setShowAnswer] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    answerId: string
  ) => {
    const userInput = e.target.value;

    setTimeout(() => {
      checkUserInputAgainstExerciseAnswer(userInput, answerId);
    }, 800);
  };

  const checkUserInputAgainstExerciseAnswer = (
    value: string,
    answerId: string
  ) => {
    const userInputStandarized = value.replace("’", "'");
    const answerText = exercise.answers.find(
      (answer) => answer.id === answerId
    )?.text;

    if (userInputStandarized === answerText) {
      audioCorrectRef && audioCorrectRef.current?.play();
      handleCorrectAnswer(answerId);
    }
  };

  const handleRevealAnswer = (e) => {
    e.preventDefault();
    setShowAnswer(!showAnswer);

    setTimeout(() => setShowAnswer(false), 2000);
  };

  const questionStringSplit = exercise.question.split(/____/);
  const hasTwoAnswers = questionStringSplit.length > 2;

  return (
    <div className="lesson-exercises-wrapper">
      <span className="my-2 flex items-center gap-3">
        {questionStringSplit[0]}

        <input
          onChange={(e) => handleChange(e, exercise.answers[0].id)}
          className={`${
            correctExerciseIds.includes(exercise.answers[0].id) ? "correct" : ""
          }`}
          disabled={correctExerciseIds.includes(exercise.answers[0].id)}
          defaultValue={
            correctExerciseIds.includes(exercise.answers[0].id)
              ? exercise.answers[0].text
              : ""
          }
        />

        {questionStringSplit[1]}

        {hasTwoAnswers && (
          <input
            onChange={(e) => handleChange(e, exercise.answers[1].id)}
            style={
              correctExerciseIds.includes(exercise.answers[0].id)
                ? {
                    backgroundColor: "rgb(14, 159, 110)",
                  }
                : {}
            }
            disabled={correctExerciseIds[1].includes(exercise.answers[1].id)}
            defaultValue={
              correctExerciseIds[1].includes(exercise.answers[1].id)
                ? exercise.answers[1].text
                : ""
            }
          />
        )}

        {hasTwoAnswers && questionStringSplit[2]}
      </span>

      <div className="flex items-center gap-2">
        {!showAnswer && (
          <button onClick={handleRevealAnswer} className="info-btn">
            Reveal Answer
          </button>
        )}
        {showAnswer && (
          <span className="text-sm font-bold underline">
            {exercise.answers.map((answer) => answer.text).join(", ")}
          </span>
        )}
      </div>
    </div>
  );
};
