const buildYoutubeVideoURL = (id) => `https://www.youtube.com/watch?v=${id}`;

const youtubeVideoList = [
  {
    type: "youtube",
    name: "דברו אנגלית עם דיויד - סיפור הצלחה של תלמיד",
    videoId: "l3Ryw8rTlp0",
  },
  {
    type: "youtube",
    name: "Testimonial about what I do in Hebrew from an IKEA Course",
    videoId: "K1OSaLx8bXI",
  },
  {
    type: "youtube",
    name: "",
    videoId: "4UxWNvTlcJc",
  },
  {
    type: "youtube",
    name: "Diplomat ISRAEL",
    videoId: "MwG-YiIILD0",
  },
  {
    type: "youtube",
    name: "YedaENG",
    videoId: "9AWKq7EoUec",
  },
  {
    type: "youtube",
    name: "",
    videoId: "uiPAMsk1-GM",
  },
  {
    type: "youtube",
    name: "",
    videoId: "uX9uWvWiJXg",
  },
];

export const videosFromClients = youtubeVideoList.map((item) => ({
  ...item,
  videoURL: buildYoutubeVideoURL(item.videoId),
}));

// Embed.
// export const buildYoutubeVideoURL = (id) =>
//   `https://www.youtube-nocookie.com/embed/${id}`;
