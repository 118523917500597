import React from "react";
import SectionTitle from "../components/sectionTitle/SectionTitle";
import "./style.scss";

const imageMinHeight = "350px";

export const TeachingApproachFlow = () => (
  <div className="teaching-approach-flow-wrapper eduvibe-home-two-testimonial">
    <div className="image-wrapper">
      <img
        style={{
          minHeight: imageMinHeight,
        }}
        className="first"
        src="https://firebasestorage.googleapis.com/v0/b/hapyspace-assets.appspot.com/o/david-english%2Fhomepage-sliders%2Fteaching-approach%2FArtboard-1_01.jpg?alt=media&token=3ed6bbc7-df0d-41ac-9aaa-94569cfc16f9"
      />
      <p className="content-wrapper">
        <SectionTitle
          classes="text-left"
          slogan="Welcome"
          title={`
              Our goal here is becoming
              <br />
              more fluent in english
            `}
        />
      </p>
    </div>

    <div className="image-wrapper">
      <img
        style={{
          minHeight: imageMinHeight,
        }}
        className="second"
        src="https://firebasestorage.googleapis.com/v0/b/hapyspace-assets.appspot.com/o/david-english%2Fhomepage-sliders%2Fteaching-approach%2FArtboard-1_02.jpg?alt=media&token=0120a563-d9fd-4956-99a8-1fb74d12b5ab"
      />
      <p className="content-wrapper">
        <SectionTitle
          classes="text-left"
          slogan="Step 1"
          title={`
            On the 1st day you
            <br />
            recorded yourself speaking
            <br />
            with me via Zoom
            `}
        />
      </p>
    </div>

    <div className="image-wrapper step-2">
      <img
        style={{
          minHeight: imageMinHeight,
        }}
        className="third"
        src="https://firebasestorage.googleapis.com/v0/b/hapyspace-assets.appspot.com/o/david-english%2Fhomepage-sliders%2Fteaching-approach%2FArtboard-1_03.jpg?alt=media&token=c50b87ff-8394-4ec1-8534-25fcf7392efb"
      />
      <p className="content-wrapper">
        <SectionTitle
          classes="text-right"
          slogan="Step 2"
          title={`
            Now you are going to do<br/> the next video in the series
            `}
        />
      </p>
    </div>

    <div className="image-wrapper step-3">
      <img
        style={{
          minHeight: imageMinHeight,
        }}
        className=""
        src="https://firebasestorage.googleapis.com/v0/b/hapyspace-assets.appspot.com/o/david-english%2Fhomepage-sliders%2Fteaching-approach%2FArtboard-1_04.jpg?alt=media&token=7ad06670-4ffd-421d-91a4-139d671d9014"
      />
      <p className="content-wrapper">
        <SectionTitle
          classes="text-left"
          slogan="Step 3"
          title={`
            Receiving feedback<br/> for all recordings
            `}
        />
      </p>
    </div>
  </div>
);

export default TeachingApproachFlow;

{
  /* <div className="mx-auto py-10">
      <Link to={"/get-in-touch"}>
        <button className="rounded-full bg-[#37B5FF] p-4 text-lg font-extrabold md:w-[230px]">
          Start Today
        </button>
      </Link>
    </div>
  </div> */
}
